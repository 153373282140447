import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client';
import { observer } from 'mobx-react'
import axios from 'axios'
import postscribe from 'postscribe'
import Slider from 'react-slick'

import MODEL from './model/model'

let latestMatchInterval;
let latestMatchListenerSet = false
let livetickerMatchSet = false

window.logout = () => MODEL.logout()

function getGermanDay(date) {
  switch (date.getDay()) {
    case 0:
      return 'So.'
    case 1:
      return 'Mo.'
    case 2:
      return 'Di.'
    case 3:
      return 'Mi.'
    case 4:
      return 'Do.'
    case 5:
      return 'Fr.'
    case 6:
      return 'Sa.'
  }
}

function getEnglishDay(date) {
  switch (date.getDay()) {
    case 0:
      return 'Sun.'
    case 1:
      return 'Mon.'
    case 2:
      return 'Tues.'
    case 3:
      return 'Wed.'
    case 4:
      return 'Thurs.'
    case 5:
      return 'Fri.'
    case 6:
      return 'Sat.'
  }
}

const LivetickerMatch = observer((props) => {

  const element = document.querySelector('.module.module-ticker .ticker')

  if (element) {

    if (!livetickerMatchSet) {

      livetickerMatchSet = true

      const matchID = element.getAttribute('data-liveticker-match-id')

      if (matchID) {
        MODEL.getSpecificMatch(matchID)
      }

      const loadMoreButton = document.querySelector('#toggle-ticker-history')

      loadMoreButton.addEventListener('click', (ev) => {
        ev.preventDefault()

        if (element.querySelector('.ticker-entries').classList.contains('show-all')) {
          element.querySelector('.ticker-entries').classList.remove('show-all')
          loadMoreButton.innerHTML = 'Mehr zeigen'
        } else {
          element.querySelector('.ticker-entries').classList.add('show-all')
          loadMoreButton.innerHTML = 'Weniger zeigen'
        }
      })
    }

    if (MODEL.specific_match) {
      const matchData = JSON.parse(MODEL.specific_match)

      if (matchData.entries_de) {

        let entries = ``

        matchData.entries_de.forEach((entry, index) => {
          entries += `<div class="ticker-entry ticker-entry--${entry.type} ${index % 2 === 0 ? `odd` : `even`}" data-id="${entry.id}">
            <div class="ticker-minute">
              ${entry.minute ? entry.minute : ``}
              ${entry.extra_time ? `<small class="ticker-additional-time badge badge-pill badge-primary">+ ${entry.extra_time}</small>` : ``}
            </div>
            <div class="ticker-type">
              <i class="fcs04-icon fcs04-icon-ticker-${entry.type}"></i>
            </div>
            <div class="ticker-comment ${entry.image ? `ticker-comment-has-image` : ``}">${entry.comment} ${entry.image ? `<img src="${entry.image}" />` : `` }</div>
            <div class="ticker-additional">
              <div class="ticker-additional--${entry.new_score ? `standing` : entry.yellow_card || entry.red_card ? `booking` : ``}">
                ${entry.new_score ? `<span class="${entry.goal_schalke ? `` : `goal-opponent`}">${entry.new_score}</span>` : `` }
                ${entry.yellow_card ? `<span class="penalty-card penalty-card--yellow"></span>` : `` }
                ${entry.red_card ? `<span class="penalty-card penalty-card--red"></span>` : `` }
              </div>
            </div>
          </div>`
        })

        if (element.querySelector('.ticker-entries').classList.contains('show-all')) {
          element.innerHTML = `<div class="ticker-entries show-all">${entries}</div>`
        } else {
          element.innerHTML = `<div class="ticker-entries">${entries}</div>`
        }
      }
    }
  }
})

const Matchcenter = observer((props) => {
  const element = document.querySelector('[data-firebase-module="matchcenter"]')

  if (element && JSON.parse(MODEL.data) && JSON.parse(MODEL.data).latest_match) {

    const latestMatch = JSON.parse(MODEL.data).latest_match

    if (!latestMatchListenerSet) {
      latestMatchListenerSet = true
      MODEL.getMatch(latestMatch.ID)
    }

    // Look for any latest match-related elements we need to potentially update.
    document.querySelectorAll(`[data-match-id="${latestMatch.ID}"]`).forEach((element) => {
      if (element.querySelector('[data-home-score]')) {
        element.querySelector('[data-home-score]').innerHTML = latestMatch.home_score ? latestMatch.home_score : `-`
      }
      if (element.querySelector('[data-away-score]')) {
        element.querySelector('[data-away-score]').innerHTML = latestMatch.away_score ? latestMatch.away_score : `-`
      }
      if (latestMatch.home_score_penalties && (typeof latestMatch.home_score_penalties === 'number' || latestMatch.home_score_penalties !== '') && element.querySelector('[data-home-score-penalties]')) {
        element.querySelector('[data-home-score-penalties]').innerHTML = latestMatch.home_score_penalties
        element.querySelector('.standing-ctx-penalties').removeAttribute('style')
      }
      if (latestMatch.away_score_penalties && (typeof latestMatch.away_score_penalties === 'number' || latestMatch.away_score_penalties !== '') && element.querySelector('[data-away-score-penalties]')) {
        element.querySelector('[data-away-score-penalties]').innerHTML = latestMatch.away_score_penalties
        element.querySelector('.standing-ctx-penalties').removeAttribute('style')
      }
      if (latestMatch.home_score_extra_time && (typeof latestMatch.home_score_extra_time === 'number' || latestMatch.home_score_extra_time !== '') && element.querySelector('[data-home-score-extra-time]')) {
        element.querySelector('[data-home-score-extra-time]').innerHTML = latestMatch.home_score_extra_time
        element.querySelector('.standing-ctx-extra-time').removeAttribute('style')
      }
      if (latestMatch.away_score_extra_time && (typeof latestMatch.away_score_extra_time === 'number' || latestMatch.away_score_extra_time !== '') && element.querySelector('[data-away-score-extra-time]')) {
        element.querySelector('[data-away-score-extra-time]').innerHTML = latestMatch.away_score_extra_time
        element.querySelector('.standing-ctx-extra-time').removeAttribute('style')
      }
      if (latestMatch.home_score_half_time && (typeof latestMatch.home_score_half_time === 'number' || latestMatch.home_score_half_time !== '') && element.querySelector('[data-home-score-half-time]')) {
        element.querySelector('[data-home-score-half-time]').innerHTML = latestMatch.home_score_half_time
        element.querySelector('.standing-ctx-score-half-time').removeAttribute('style')
      }
      if (latestMatch.away_score_half_time && (typeof latestMatch.away_score_half_time === 'number' || latestMatch.away_score_half_time !== '') && element.querySelector('[data-away-score-half-time]')) {
        element.querySelector('[data-away-score-half-time]').innerHTML = latestMatch.away_score_half_time
        element.querySelector('.standing-ctx-score-half-time').removeAttribute('style')
      }

      if (latestMatch.home_scorers && element.querySelector('[data-goals-home]')) {

        let homeScorersMarkup = ``

        latestMatch.home_scorers.forEach((goal) => {
          homeScorersMarkup += `<div class="team-goal">${goal.minute}.’ ${goal.name}</div>`
        })

        element.querySelector('[data-goals-home]').innerHTML = homeScorersMarkup
      }
      if (latestMatch.away_scorers && element.querySelector('[data-goals-away]')) {

        let awayScorersMarkup = ``

        latestMatch.away_scorers.forEach((goal) => {
          awayScorersMarkup += `<div class="team-goal">${goal.minute}.’ ${goal.name}</div>`
        })

        element.querySelector('[data-goals-away]').innerHTML = awayScorersMarkup
      }

      const match = JSON.parse(MODEL.latest_match)

      if (match && element.querySelector('.ticker-entries')) {

        let livetickerMarkup = ``

        match.entries_de.forEach((entry) => {
          livetickerMarkup += `<div class="ticker-entry">
            <div class="ticker-minute">${entry.minute}. ${entry.extra_time ? `<small class="ticker-additional-time badge badge-pill badge-primary">+ ${entry.extra_time}</small>` : `` }</div>
            <div class="ticker-type">
              ${entry.type === 'comment' ? `` : ``}
              ${entry.type === 'goal' ? `<i class="fcs04-icon fcs04-icon-ticker-goal"></i>` : ``}
              ${entry.type === 'break' ? `<i class="fcs04-icon fcs04-icon-ticker-break"></i>` : ``}
              ${entry.type === 'substitution' ? `<i class="fcs04-icon fcs04-icon-ticker-substitution"></i>` : ``}
              ${entry.type === 'booking' ? `<i class="fcs04-icon fcs04-icon-ticker-booking"></i>` : ``}
              ${entry.type === 'video-assistent' ? `<i class="fcs04-icon fcs04-icon-ticker-video-assistent"></i>` : ``}
            </div>
            <div class="ticker-comment ${entry.image ? `ticker-comment-has-image` : ``}">
              ${entry.image ? `<img src="${entry.image}" />` : `` }
              ${entry.comment}
            </div>
            <div class="ticker-additional">
              ${entry.new_score ? `<div class="ticker-additional--standing"><span class="${!entry.goal_schalke ? `goal-opponent` : ``}">${entry.new_score}</span></div>` : ``}
              ${entry.yellow_card ? `<div class="ticker-additional--booking"><span class="penalty-card penalty-card--yellow"></span></div>` : ``}
              ${entry.red_card ? `<div class="ticker-additional--booking"><span class="penalty-card penalty-card--red"></span></div>` : ``}
              ${entry.yellow_red_card ? `<div class="ticker-additional--booking"><span class="penalty-card penalty-card--yellow"></span><span class="penalty-card penalty-card--red"></div>` : ``}
            </div>
          </div>`

          element.querySelector('.ticker-entries').innerHTML = livetickerMarkup
          element.querySelector('.ticker-entries').classList.remove('ctx-loading')

          if (element.querySelector('.show-more')) {
            element.querySelector('.show-more').style = 'display: none'
          }
        })
      }

      if (match && match.statistics) {

        const stats = match.statistics

        let statsByType = {}

        stats.forEach((stat) => {
          statsByType[stat.label] = {
            home: stat.home_team,
            away: stat.away_team
          }
        })

        element.querySelectorAll('[data-stat-type]').forEach((element) => {

          const type = element.getAttribute('data-stat-type')

          if (statsByType[type]) {
            element.querySelector('[data-stat-home]').innerHTML = statsByType[type].home
            element.querySelector('[data-stat-away]').innerHTML = statsByType[type].away

            let percentageHome = parseFloat(statsByType[type].home) / (parseFloat(statsByType[type].home) + parseFloat(statsByType[type].away)) * 100
            let percentageAway = parseFloat(statsByType[type].away) / (parseFloat(statsByType[type].home) + parseFloat(statsByType[type].away)) * 100

            element.querySelector('[data-stat-bar-home]').style.width = `${percentageHome}%`
            element.querySelector('[data-stat-bar-away]').style.width = `${percentageAway}%`
          }
        })
      }

      if (match && match.substitutions) {

        const subs = match.substitutions
        const subsElements = element.querySelectorAll('.statistics-changes--team')

        if (subsElements.length === 2) {

          let subsHome = subsElements[0]
          let subsAway = subsElements[1]

          let subsHomeMarkup = ``
          let subsAwayMarkup = ``

          subs.home_team.forEach((sub) => {
            subsHomeMarkup += `<div class="statistic-change">
              <span class="out">${sub.minute}' ${sub.out}</span>
              <span class="in">${sub.in}</span>
            </div>`
          })

          subs.away_team.forEach((sub) => {
            subsAwayMarkup += `<div class="statistic-change">
              <span class="out">${sub.minute}' ${sub.out}</span>
              <span class="in">${sub.in}</span>
            </div>`
          })

          subsHome.innerHTML = subsHomeMarkup
          subsAway.innerHTML = subsAwayMarkup
        }
      }
    })

    document.querySelectorAll('[data-match-id].ctx-loading, [data-match-id] .ctx-loading').forEach((element) => {
      element.classList.remove('ctx-loading')
    })
  }
})

let searchIsReady = false;
const itemsToShow = 8;

const Search = observer((props) => {

  if (!searchIsReady) {

    searchIsReady = true

    const elements = document.querySelectorAll('[data-firebase-search]')

    elements.forEach((element) => {

      const LANGUAGE = element.getAttribute('data-firebase-search')

      // Check for default criteria
      if (window.location.search && decodeURI(window.location.search.replace('?search=', ''))) {
        let criteria = decodeURI(window.location.search.replace('?search=', ''))
        document.querySelector('[data-firebase-search] [name="search"]').value = criteria
        search(criteria, LANGUAGE)
      } else {
        // Show empty search by default

        // Set loading state.
        document.querySelector('[data-module="modules/search"]').style.display = 'none'
        if (document.querySelector('[data-firebase-search-parent]')) {
          document.querySelector('[data-firebase-search-parent]').classList.remove('loading')
        }

        // Reset potential previous hides
        document.querySelector('#result-news').style.display = 'none'
        document.querySelector('#result-pages').style.display = 'none'
        document.querySelector('#result-videos').style.display = 'none'
      }

      // Listen for new searches
      document.querySelector('[data-firebase-search] [name="form-search"]').addEventListener('submit', (ev) => {
        ev.preventDefault()

        const criteria = document.querySelector('[data-firebase-search] [name="search"]').value

        if (criteria) {
          search(criteria, LANGUAGE)
        }
      })

      // Listen for "load more" clicks
      document.addEventListener('click', function(e) {

        const target = e.target;
        const element = target.closest("[data-load-more-results]");

        if (element) {

          e.preventDefault()

          const type = element.getAttribute('data-load-more-results');

          // See how many posts we have.
          const hiddenPosts = document.querySelectorAll(`[data-search-results-container="${type}"] .card-deck a.hidden`);

          // Show the next 5 posts.
          for (let i = 0; i < itemsToShow; i++) {
            if (hiddenPosts[i]) {
              hiddenPosts[i].classList.remove('hidden');
            }
          }

          // Check if we have any hidden posts left.
          if (hiddenPosts.length - itemsToShow <= 0) {
            element.style.display = 'none';
          }

          // Unfocus from button.
          element.blur();
        }
      }, false);

      // Listen for filters (desktop)
      document.querySelectorAll('[data-module="modules/search"] [data-relatesto]').forEach((element) => {
        element.addEventListener('click', (ev) => {
          ev.preventDefault()

          let identifier = ''

          if (ev.target.getAttribute('data-relatesto') === 'result-news') {
            identifier = '#result-news'
          } else if (ev.target.getAttribute('data-relatesto') === 'result-pages') {
            identifier = '#result-pages'
          } else if (ev.target.getAttribute('data-relatesto') === 'result-videos') {
            identifier = '#result-videos'
          } else if (ev.target.getAttribute('data-relatesto') === 'all') {
            identifier = '[name="form-filter-search"]'
          }

          // eslint-disable-next-line
          jQuery([document.documentElement, document.body]).animate({
            // eslint-disable-next-line
            scrollTop: jQuery(identifier).offset().top - 150
          }, 300);
        })
      })

      // Listen for filters (mobile)
      document.querySelector('[name="form-filter-search"] select').addEventListener('change', (ev) => {
        let identifier = ''

        identifier = ev.target.value

        // eslint-disable-next-line
        jQuery([document.documentElement, document.body]).animate({
          // eslint-disable-next-line
          scrollTop: jQuery(identifier).offset().top - 150
        }, 300);
      })
    })
  }
})

function search(criteria, language) {
  return new Promise((resolve, reject) => {

    // Set loading state.
    document.querySelector('[data-module="modules/search"]').style.display = 'block'
    document.querySelector('[data-firebase-search-parent]').classList.add('loading')

    // Reset potential previous hides
    document.querySelector('#result-news').style.display = 'block'
    document.querySelector('#result-pages').style.display = 'block'
    document.querySelector('#result-videos').style.display = 'block'

    searchRequest(criteria, language).then((results) => {
      let totalCount = 0
      let collection = {
        'post': [],
        'page': [],
        'video': []
      }

      results.forEach((item) => {
        item = item._source
        collection[item.type].push(item)
      })

      // Display results
      Object.keys(collection).forEach((type) => {

        let items = collection[type]

        totalCount = totalCount + items.length

        // POSTS
        let markup = ``

        // Limit each "type" to X results.
        let countPosts = 0;
        let countPages = 0;
        let countVideos = 0;

        items.forEach((item, index) => {

          // Convert english date to german date
          item.meta.date = item.meta.date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1')

          let image = ''

          if (type === 'post') {

            countPosts++;

            if (item.image) {
              image = `<div class="card-img-top teaser-image-container">
                <div class="teaser-image objectfit">
                  <img class="img-fluid initial lazyloaded" src="${item.image}">
                </div>
              </div>`
            }

            markup += `<a href="${item.url}" class="teaser teaser-article card  card-24 card-sm-12 card-lg-8 card-xl-6 ${countPosts > itemsToShow ? 'hidden' : null}">
              ${image}
              <div class="teaser-body card-block">
                <h3 class="card-title teaser-title">${item.title}</h3>
                <p class="card-text teaser-copy">${item.description}</p>
                <div class="card-footer teaser-footer">
                  <p class="teaser-category">${item.meta.category}</p>
                  <p class="teaser-date" title="${item.meta.date}">${item.meta.date}</p>
                </div>
              </div>
            </a>`

          } else if (type === 'page') {

            countPages++;

            if (item.image) {
              image = `<div class="card-img-top teaser-image-container">
                <div class="teaser-image objectfit">
                  <img class="img-fluid lazyloaded" src="${item.image}">
                </div>
              </div>`
            }

            markup += `<a href="${item.url}" class="teaser teaser-page teaser-overlay card card-inverse card-24 card-sm-12 card-lg-8 ${countPages > itemsToShow ? 'hidden' : null}" style="min-height: 300px">
              ${image}
              <div class="teaser-body card-img-overlay">
                <h3 class="card-title teaser-title">${item.title}</h3>
              </div>
            </a>`

          } else if (type === 'video') {

            countVideos++;

            if (item.image) {
              image = `<div class="card-img-top teaser-image-container">
                <div class="teaser-image objectfit">
                  <img class="img-fluid lazyloaded" src="${item.image}">
                </div>
                <div class="teaser-video-play"></div>
              </div>`
            }

            markup += `<a href="${item.url}" class="teaser teaser-video card  card-24 card-sm-12 card-lg-8 ${countVideos > itemsToShow ? 'hidden' : null}">
              ${image}
              <div class="teaser-body card-block">
                <h3 class="card-title teaser-title">
                  ${item.title}
                </h3>
                <div class="card-footer teaser-footer">
                  <p class="teaser-category">
                    ${item.meta.category}
                  </p>
                  <p class="teaser-date" title="${item.meta.date}">
                    ${item.meta.date}
                  </p>
                </div>
              </div>
            </a>`
          }
        })

        if (type === 'post') {
          document.querySelector('[data-relatesto="result-news"] strong').innerHTML = items.length

          if (items.length === 0) {
            // Hide the section
            document.querySelector('#result-news').style.display = 'none'
          }
        } else if (type === 'page') {
          document.querySelector('[data-relatesto="result-pages"] strong').innerHTML = items.length

          if (items.length === 0) {
            // Hide the section
            document.querySelector('#result-pages').style.display = 'none'
          }
        } else if (type === 'video') {
          document.querySelector('[data-relatesto="result-videos"] strong').innerHTML = items.length

          if (items.length === 0) {
            // Hide the section
            document.querySelector('#result-videos').style.display = 'none'
          }
        }

        if (document.querySelector(`[data-search-results-container="${type}"] .card-deck`)) {

          if (
            (type === 'post' && countPosts > itemsToShow) ||
            (type === 'page' && countPages > itemsToShow) ||
            (type === 'video' && countVideos > itemsToShow)
          ) {
            // Add "load more" button
            markup += `<div class="row col-24">
              <div class="col-24">
                <div class="show-more">
                  <a href="#" class="btn btn-secondary-gradient" data-load-more-results="${type}">Mehr zeigen</a>
                </div>
              </div>
            </div>`
          }

          document.querySelector(`[data-search-results-container="${type}"] .card-deck`).innerHTML = markup
        }
      })

      document.querySelector('[data-search-total-count]').innerHTML = totalCount

      if (totalCount === 0) {
        document.querySelector('[data-module="modules/search"]').style.display = 'none'
      }

      if (document.querySelector('[data-firebase-search-parent]')) {
        document.querySelector('[data-firebase-search-parent]').classList.remove('loading')
      }

    }).catch((err) => {
      console.log('error -> ', err)
    })
  })
}

function searchRequest(criteria, language) {
  return new Promise((resolve, reject) => {

    axios
      // .post(`http://localhost:5001/schalke-04-app/europe-central2/search`,
      .post(`https://europe-central2-schalke-04-app.cloudfunctions.net/search`,
        {
          search_criteria: criteria,
          language
        }
      )
      .then((response) => {

        let allResults = [];

        response.data.results.forEach((result) => {
          allResults.push({
            _source: {
              description: result._source.description,
              id: result._source.id,
              image: result._source.image,
              meta: {
                category: result._source.meta.category,
                date: result._source.meta.date,
              },
              title: result._source.title,
              type: result._source.type,
              url: result._source.url,
            }
          })
        })

        // Sort allResults by date descending.
        allResults.sort((a, b) => {
          // Convert german date format to english date format.
          a._source.meta.date = a._source.meta.date.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$3-$2-$1')
          b._source.meta.date = b._source.meta.date.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$3-$2-$1')
          return new Date(b._source.meta.date) - new Date(a._source.meta.date);
        });

        resolve(allResults)
      })
    })
}

// Get menu items from Firebase.
MODEL.getSearchShortcuts()

let searchShortcutsAreReady = false

const SearchShortcuts = observer((props) => {

  if (!searchShortcutsAreReady) {

    searchShortcutsAreReady = true

    let listItems = '';

    // Which language is active?
    const element = document.querySelector('[data-firebase-module="menu"]')
    const language = element ? element.getAttribute('language') : 'de';

    const parent = document.querySelector('#header-search');
    let shortcuts = document.querySelector('#search-shortcuts');

    if (parent && !shortcuts) {
      shortcuts = document.createElement('div')
      shortcuts.setAttribute('id', 'search-shortcuts')
      shortcuts.classList.add('search-shortcuts')
      parent.appendChild(shortcuts)
    }

    if (MODEL.search_shortcuts && JSON.parse(MODEL.search_shortcuts)) {

      if (language === 'en') {
        JSON.parse(MODEL.search_shortcuts)['en'].map((item) => {
          listItems += `<li class="list-inline-item"><a class="tag" href="${item.link}" target="${item.target}">${item.label}</a></li>`
        })
      } else { // fallback to DE.
        JSON.parse(MODEL.search_shortcuts)['de'].map((item) => {
          listItems += `<li class="list-inline-item"><a class="tag" href="${item.link}" target="${item.target}">${item.label}</a></li>`
        })
      }

      if (parent && listItems) {

        shortcuts.innerHTML = `
        <div class="container">
          <div class="row">
            <div class="col-xl-23 offset-xl-1 col-xxl-23 offset-xxl-1 col-xxxl-24 offset-xxxl-0">
              <div class="tag-list" style="margin: 30px 0 0;">
                <ul class="list-inline" style="margin: 0; ">
                  ${listItems}
                </ul>
              </div>
            </div>
          </div>
        </div>
        `
      }
    }
  }
})

const Advert = observer((props) => {
  loadAllAdverts(props);
})

function loadAdvertAsync(element, campaign) {
  return new Promise(resolve => {
    loadAdvert(element, campaign);
    resolve();
  });
}

function loadAllAdverts(props) {
  const advertsByID = {}
  props.data.adverts.map((advert) => {
    advertsByID[advert.id] = advert
  })

  const advertsByType = {}
  props.data.adverts.map((advert, index) => {
    if (advert.start <= new Date().getTime() / 1000 && advert.end >= new Date().getTime() / 1000 || index === 0) {
      if (!advertsByType[advert.type]) advertsByType[advert.type] = []
      advertsByType[advert.type].push(advert)
    }
  })

  const elements = document.querySelectorAll('[data-firebase-advert]');
  const loadPromises = [];

  elements.forEach((element) => {

    let campaigns = element.getAttribute('data-campaigns')

    if (campaigns) {

      campaigns = campaigns.trim().split(' ')

      const randomCampaign = campaigns[Math.floor(Math.random()*campaigns.length)]

      if (randomCampaign) {

        const foundCampaign = advertsByID[randomCampaign]

        if (foundCampaign) {
          loadPromises.push(loadAdvertAsync(element, foundCampaign));
        }
      }

    } else {

      // There might not have been any campaign IDs provided.
      const type = element.getAttribute('data-type')

      if (type && advertsByType[type]) {
        const index = Math.floor(Math.random()*advertsByType[type].length)
        const foundCampaign = advertsByType[type][index]
        if (foundCampaign) {
          loadPromises.push(loadAdvertAsync(element, foundCampaign));
        }
      }
    }
  });

  Promise.all(loadPromises).then(() => {
    window.addEventListener('blur', function() {
      setTimeout(function() {
        var iframe = document.activeElement;
        if (iframe.tagName === 'IFRAME' && iframe.className.includes('banner')) {
          var dataType = iframe.getAttribute('data-type');

          var adType = 'Content Ad';
          if (dataType === '1') {
            document.querySelectorAll('iframe[data-type="1"]').forEach((el, i) => {
              if (el.id === iframe.id) {
                if (i === 0) {
                  adType = 'oberes Leaderboard';
                } else {
                  adType = 'unteres Leaderboard';
                }
              }
            });
          }

          var iframeSrc = iframe ? iframe.getAttribute('src') : '';
          window.emos3.send({
            pageId: window.location.pathname,
            Target: [adType, 'Klick/' + iframeSrc, 1, 'd'],
            content: window.location.pathname,
            rgtype: 'hiddenpi'
          });
          window.focus();
        }
      }, 0);
    });
  });
}

function loadAdvert(element, foundCampaign) {
  if (foundCampaign.type === '1') {

    element.innerHTML = `
      <div class="module module-advertising module-advertising--leaderboard d-none d-lg-block">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-lg" class="banner" src="${foundCampaign.large}" frameborder="0"
              marginheight="0" marginwidth="0" width="768" scrolling="no" height="90"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>

      <div class="module module-advertising module-advertising--leaderboard d-none d-md-block d-lg-none">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-md" class="banner" src="${foundCampaign.medium}" frameborder="0"
              marginheight="0" marginwidth="0" width="768" scrolling="no" height="90"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>

      <div class="module module-advertising module-advertising--leaderboard d-md-none">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-sm" class="banner" src="${foundCampaign.small}" frameborder="0"
              marginheight="0" marginwidth="0" width="300" scrolling="no" height="250"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>
    `
  } else if (foundCampaign.type === '2') {

    element.innerHTML = `
      <div class="module module-advertising d-none d-lg-block">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-lg" class="banner" src="${foundCampaign.large}" frameborder="0"
              marginheight="0" marginwidth="0" width="300" scrolling="no" height="250"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>

      <div class="module module-advertising d-none d-md-block d-lg-none">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-md" class="banner" src="${foundCampaign.medium}" frameborder="0"
              marginheight="0" marginwidth="0" width="300" scrolling="no" height="250"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>

      <div class="module module-advertising d-md-none">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-sm" class="banner" src="${foundCampaign.small}" frameborder="0"
              marginheight="0" marginwidth="0" width="300" scrolling="no" height="250"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>
    `
  } else if (foundCampaign.type === '3') {
    element.innerHTML = `
      <div class="module module-advertising module-advertising--leaderboard d-none d-lg-block">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-lg" class="banner" src="${foundCampaign.large}" frameborder="0"
              marginheight="0" marginwidth="0" width="970" scrolling="no" height="250"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>

      <div class="module module-advertising module-advertising--leaderboard d-none d-md-block d-lg-none">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-md" class="banner" src="${foundCampaign.medium}" frameborder="0"
              marginheight="0" marginwidth="0" width="768" scrolling="no" height="90"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>

      <div class="module module-advertising module-advertising--leaderboard d-md-none">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-sm" class="banner" src="${foundCampaign.small}" frameborder="0"
              marginheight="0" marginwidth="0" width="300" scrolling="no" height="250"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>
    `
  } else if (foundCampaign.type === '4') {

    element.innerHTML = `
      <div class="module module-advertising module-advertising--leaderboard d-none d-lg-block">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-lg" class="banner" src="${foundCampaign.large}" frameborder="0"
              marginheight="0" marginwidth="0" width="768" scrolling="no" height="200"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>

      <div class="module module-advertising module-advertising--leaderboard d-none d-md-block d-lg-none">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-md" class="banner" src="${foundCampaign.medium}" frameborder="0"
              marginheight="0" marginwidth="0" width="768" scrolling="no" height="200"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>

      <div class="module module-advertising module-advertising--leaderboard d-md-none">
          <span class="ad">Anzeige</span>
          <iframe data-type="${foundCampaign.type}" id="campaign-${foundCampaign.id}-sm" class="banner" src="${foundCampaign.small}" frameborder="0"
              marginheight="0" marginwidth="0" width="320" scrolling="no" height="50"
              top="0" left="0" style="display:block;margin:10px auto;">Your Browser doesn't support Iframes.</iframe>
      </div>
    `
  }
}

const Video = observer((props) => {
  const element = document.querySelector('[data-firebase-module="video"]')

  if (element) {

    const videoID = element.getAttribute('data-video-id')

    element.classList.add('loading')

    // If the user is logged in, see if they can access the video as per Firestore rules.
    MODEL.getVideo(videoID).then((video) => {
      element.innerHTML = `<div style="position: relative; padding-bottom: 56.25%;"><iframe id="sdn1028136548" title="3Q SDN" width="640px" height="360px" src="https://playout.3qsdn.com/${video.media}" frameborder="0" scrolling="no" allow="autoplay; picture-in-picture; encrypted-media; fullscreen" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`
    }).catch(() => {

      MODEL.getUser().then((user) => {

        // Show paywall
        element.innerHTML = `
          <div id="video-paywall" class="video-paywall paywall-visible" style="">
            <div class="row">
              <div class="offset-md-1 col-md-13 offset-xl-2 col-xl-10">
                <h2 class="h1">Tu dat hier nich direkt wechklicken!</h2>
                <p>Du kannst dieses Video als Mitglied kostenlos weiterschauen. Meld dich ganz einfach mit deinen Zugangsdaten an (oder registriere dich einmalig) und genieß die exklusiven Mitglieder-Vorteile auf Schalke TV sowie im neuen Digitalen Vereinsheim! </p>
                
                <p style="margin-bottom: 20px; color: rgb(255, 124, 40); display: block; font-size: 16px; font-weight: bold;" data-firebase="non-member-overlay-warning">Nicht geklappt? Dann bist du mit der E-Mail-Adresse nicht als Mitglied registriert!</p>

                <div class="paywall-cta">
                  <div class="row">
                    <div class="col-md-10 col-xl-6">
                      <p><small>Du bist bereits Mitglied?</small></p>
                      <a href="/account/profil" class="btn btn-secondary-gradient">Jetzt anmelden</a>
                    </div>
                    <div class="col-md-10 offset-xl-2 col-xl-8">
                      <p><small>Noch kein Teil der Vereinsfamilie?</small></p>
                      <a href="https://schalke04.de/mitglieder-fans/mitgliedschaft/online-antrag/" class="btn btn-primary-gradient" target="_blank">Zum Mitgliedsantrag</a>
                    </div>
                  </div>
                </div>
                <small></small>
              </div>
            </div>
          </div>
        `

      }).catch(() => {

        // Show login wall
        element.innerHTML = `
          <div id="video-paywall" class="video-paywall paywall-visible" style="">
            <div class="row">
              <div class="offset-md-1 col-md-13 offset-xl-2 col-xl-10">
                <h2 class="h1">Tu dat hier nich direkt wechklicken!</h2>
                <p>Du kannst dieses Video als Mitglied kostenlos weiterschauen. Meld dich ganz einfach mit deinen Zugangsdaten an (oder registriere dich einmalig) und genieß die exklusiven Mitglieder-Vorteile auf Schalke TV sowie im neuen Digitalen Vereinsheim! </p>
                
                <div class="paywall-cta">
                  <div class="row">
                    <div class="col-md-10 col-xl-6">
                      <p><small>Du bist bereits Mitglied?</small></p>
                      <a href="/account/profil" class="btn btn-secondary-gradient">Jetzt anmelden</a>
                    </div>
                    <div class="col-md-10 offset-xl-2 col-xl-8">
                      <p><small>Noch kein Teil der Vereinsfamilie?</small></p>
                      <a href="https://schalke04.de/mitglieder-fans/mitgliedschaft/online-antrag/" class="btn btn-primary-gradient" target="_blank">Zum Mitgliedsantrag</a>
                    </div>
                  </div>
                </div>
                <small></small>
              </div>
            </div>
          </div>
        `
      })
    })

    element.classList.remove('loading')
  }
})

const GlobalHospitality = observer((props) => {

  const token = localStorage.getItem('token')
  const parsedToken = parseJwt(token)
  if(!parsedToken) return;

  const claims = parsedToken.claims

  if (claims.hospitality_role) {
    switch (claims.hospitality_role.toLowerCase()) {
      case 'admin partner':
        document.querySelectorAll('.menu-item-hospitality').forEach((element) => {
          element.classList.remove('menu-item-hospitality')
        })
        document.querySelectorAll('.menu-item-hospitality-partner').forEach((element) => {
          element.classList.remove('menu-item-hospitality-partner')
        })
        document.querySelectorAll('.section-item-hospitality').forEach((element) => {
          element.classList.remove('section-item-hospitality')
        })
        document.querySelectorAll('.section-item-hospitality-partner').forEach((element) => {
          element.classList.remove('section-item-hospitality-partner')
        })
        document.querySelectorAll('.section-item-hospitality').forEach((element) => {
          element.classList.remove('section-item-hospitality')
        })
        document.querySelectorAll('.section-item-hospitality').forEach((element) => {
          element.classList.remove('section-item-hospitality')
        })
        document.querySelectorAll('.single-item-hospitality-partner').forEach((element) => {
          element.classList.remove('single-item-hospitality-partner')
        })
        document.querySelectorAll('.single-item-hospitality-partner-admin').forEach((element) => {
          element.classList.remove('single-item-hospitality-partner-admin')
        })
      break;
      case 'admin abo':
        document.querySelectorAll('.menu-item-hospitality').forEach((element) => {
          element.classList.remove('menu-item-hospitality')
        })
        document.querySelectorAll('.menu-item-hospitality-abo').forEach((element) => {
          element.classList.remove('menu-item-hospitality-abo')
        })
        document.querySelectorAll('.section-item-hospitality').forEach((element) => {
          element.classList.remove('section-item-hospitality')
        })
        document.querySelectorAll('.section-item-hospitality-abo').forEach((element) => {
          element.classList.remove('section-item-hospitality-abo')
        })
        document.querySelectorAll('.section-item-hospitality').forEach((element) => {
          element.classList.remove('section-item-hospitality')
        })
        document.querySelectorAll('.section-item-hospitality').forEach((element) => {
          element.classList.remove('section-item-hospitality')
        })
        document.querySelectorAll('.single-item-hospitality').forEach((element) => {
          element.classList.remove('single-item-hospitality')
        })
        document.querySelectorAll('.single-item-hospitality-abo').forEach((element) => {
          element.classList.remove('single-item-hospitality-abo')
        })
        document.querySelectorAll('.single-item-hospitality-partner-user').forEach((element) => {
          element.classList.remove('single-item-hospitality-partner-user')
        })
      break;
    }
  }
})

const Account = observer((props) => {
  const element = document.querySelector('[data-firebase-module="account"]')
  const form = document.querySelector("[name='form-edit-profile']")

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  if (params.token) {
    MODEL.login(params.token).then(() => {
      if (localStorage.getItem('redirect')) {
        const redirect = localStorage.getItem('redirect')
        localStorage.removeItem('redirect');

        window.location = redirect
      }
      window.parseToken()
    })
  }

  const token = localStorage.getItem('token')

  // Convert login button to a profile button
  const loginButtons = document.querySelectorAll('[data-firebase-reference="account-login-link"]')
  loginButtons.forEach((button) => {
    if (token && token !== '') {
      button.innerHTML = 'Profil'
    }
    button.onclick = (ev) => {
      ev.preventDefault();
      localStorage.setItem('redirect', window.location.href);
      window.location = button.getAttribute('href')
    }
  })

  if (element && form) {

    if (!token || token === '') {
      if (window.location.host === 'internal.schalke04.de') {
        window.location = `https://europe-central2-schalke-04-app.cloudfunctions.net/login?project=internal`;
      } else if (window.location.host === 'preview.schalke04.de') {
        window.location = `https://europe-central2-schalke-04-app.cloudfunctions.net/login?project=preview`;
      } else {
        window.location = 'https://europe-central2-schalke-04-app.cloudfunctions.net/login?project=web';
      }
    }

    // We just need to read through the user's stored JWT for this step.
    const parsedToken = parseJwt(token)
    const claims = parsedToken.claims

    // If we have the user's SubscriberKey and Hash, we can show the ExactTarget preferences iframe.
    if (claims.subscriber_key && claims.hash) {
      document.querySelector('[data-firebase-module="newsletter-preferences-iframe"]').setAttribute('src', `https://pub.ev.schalke04.de/devprofilecenter?SubscriberKey=${claims.subscriber_key}&hash=${claims.hash}`)
      document.querySelector('[data-firebase-module="newsletter-preferences-iframe"]').style.display = 'block'
    }

    // Logout
    document.querySelector('.stage-profile--logout').addEventListener('click', (ev) => {
      ev.preventDefault()
      MODEL.logout()
      window.location = '/'
    })

    document.querySelector('[data-account-placeholder="input_first_name"]').value = claims.first_name
    document.querySelector('[data-account-placeholder="first_name"]').innerHTML = `${claims.first_name}!`
    document.querySelector('[data-account-placeholder="input_last_name"]').value = claims.last_name
    document.querySelector('[data-account-placeholder="input_email"]').value = claims.email
    document.querySelector('[data-account-placeholder="input_birthday"]').value = claims.birthday
    document.querySelector('[data-account-placeholder="input_street"]').value = claims.street
    document.querySelector('[data-account-placeholder="input_post_code"]').value = claims.post_code
    document.querySelector('[data-account-placeholder="input_city"]').value = claims.city
    document.querySelector('[data-account-placeholder="input_telephone"]').value = claims.telephone
    document.querySelector('[data-account-placeholder="text_hospitality_name"]').innerHTML = claims.hospitality_name
    document.querySelector('[data-account-placeholder="text_hospitality_role"]').innerHTML = claims.hospitality_role

    if (document.querySelector('[data-account-placeholder="is_not_newsletter_subscriber"]')) {
      if (claims.newsletter) {
        document.querySelector('[data-account-placeholder="is_not_newsletter_subscriber"]').style = 'display: none'
      } else {
        document.querySelector('[data-account-placeholder="is_newsletter_subscriber"]').style = 'display: none'
      }
    }

    if (document.querySelector('[data-account-placeholder="is_not_tv_subscriber"]')) {
      if (claims.member) {
        document.querySelector('[data-account-placeholder="is_not_tv_subscriber"]').style = 'display: none'
      } else {
        document.querySelector('[data-account-placeholder="is_tv_subscriber"]').style = 'display: none'
      }
    }

    if (document.querySelector('[data-account-placeholder="is_press"]')) {
      if (claims.press) {
        document.querySelector('[data-account-placeholder="is_press"]').style = 'display: block'
      } else {
        document.querySelector('[data-account-placeholder="is_press"]').style = 'display: none'
      }
    }

    if (document.querySelector('[data-account-placeholder="is_not_schalke_member"]')) {
      if (claims.member) {
        document.querySelector('[data-account-placeholder="is_not_schalke_member"]').style = 'display: none'
      } else {
        document.querySelector('[data-account-placeholder="is_schalke_member"]').style = 'display: none'
      }
    }

    if (document.querySelector('[data-account-placeholder="tab_hospitality"]')) {
      if (claims.hospitality) {
        document.querySelector('[data-account-placeholder="tab_hospitality"]').style = 'display: block'
      }
    }

    if (document.querySelector('[data-account-placeholder="tab_werbemassnahmen"]')) {
      if (claims.hospitality_role && claims.hospitality_role.toLowerCase().includes('admin partner')) {
        document.querySelector('[data-account-placeholder="tab_werbemassnahmen"]').style = 'display: block'
      }
    }

    if (document.querySelector('.account-tabs')) {

      const tabs = document.querySelectorAll('.account-tabs a')

      tabs.forEach((tab) => {
        tab.addEventListener('click', (ev) => {
          ev.preventDefault();

          const element = ev.target
          const source = element.getAttribute('data-account-placeholder') ? element : element.parentElement
          const tab = source.getAttribute('data-account-placeholder')
          const target = document.querySelector(`.account-tabs-content [data-tab="${tab}"]`)

          const allTabs = document.querySelectorAll(`.account-tabs-content [data-tab]`)

          allTabs.forEach((tab) => {
            tab.style.display = 'none'
          })

          target.style.display = 'block'

          const allTabTriggers = document.querySelectorAll(`.account-tabs [data-account-placeholder]`)

          allTabTriggers.forEach((tab) => {
            tab.classList.remove('active')
          })

          source.classList.add('active')
        })
      })
    }

    element.classList.remove('is-loading')

    // Handle profile form submissions
    document.querySelector("[name='form-edit-profile']").removeEventListener("submit", handleProfileFormSubmissions)
    // Remove any previous handlers
    document.querySelector("[name='form-edit-profile']").addEventListener("submit", handleProfileFormSubmissions)
  }
})

function handleProfileFormSubmissions(ev) {
  ev.preventDefault()

  let details = {
    street: document.querySelector("[name='form-edit-profile'] [name='street']").value,
    post_code: document.querySelector("[name='form-edit-profile'] [name='post_code']").value,
    city: document.querySelector("[name='form-edit-profile'] [name='city']").value,
    telephone: document.querySelector("[name='form-edit-profile'] [name='telephone']").value
  }

  if (details.street && details.post_code && details.city && details.telephone) {
    // Check if the user is already authenticated.
    MODEL.getUser().then((user) => {
      submitProfileForm(details.street, details.post_code, details.city, details.telephone)
    }).catch((err) => {
      // Before trying to obtain protected content, check to see if we have a customToken which should be exchanged for a Firebase session.
      const token = localStorage.getItem('token')

      if (!token) {
        // Send to login
        if (getCookie('state')) {
          window.location = 'https://europe-central2-schalke-04-app.cloudfunctions.net/login?state=local';
        } else {
          window.location = 'https://europe-central2-schalke-04-app.cloudfunctions.net/login';
        }
      }

      MODEL.login(token).then((user) => {
        submitProfileForm(details.street, details.post_code, details.city, details.telephone)
      }).catch((err) => {
        // Send to login
        if (getCookie('state')) {
          window.location = 'https://europe-central2-schalke-04-app.cloudfunctions.net/login?state=local';
        } else {
          window.location = 'https://europe-central2-schalke-04-app.cloudfunctions.net/login';
        }
      })
    })
  }
}

function submitProfileForm(street, post_code, city, telephone) {
  return new Promise((resolve, reject) => {

    MODEL.getToken().then((token) => {
      axios
      // .post(`http://localhost:5001/schalke-04-app/europe-central2/updateProfile`,
      .post(`https://europe-central2-schalke-04-app.cloudfunctions.net/updateProfile`,
        {
          street,
          city,
          post_code,
          telephone
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((response) => {
        resolve()
      })
    })
  })
}

const MemberWall = observer((props) => {
  const element = document.querySelector('[data-firebase-module="memberwall"]')
  const postID = document.querySelector('body').getAttribute('postid')
  let hlsLoaded = false;

  if (element && postID) {

    const isPressPage = document.querySelector('body').getAttribute('press-page') ? true : false

    const pageContent = document.querySelector('[data-firebase-reference="page-content"]')
    const language = element.getAttribute('language')

    const memberwallMarkup = language === 'en' ? props.data.member_wall_en : language === 'zh-hans' ? props.data.member_wall_zh_hans : props.data.member_wall_de
    const presswallMarkup = props.data.press_wall

    // Check if the user is already authenticated.
    MODEL.getUser().then((user) => {
      // Check if this user has access to the page (as it's a private page)
      MODEL.getPage(postID, isPressPage).then((page) => {
        pageContent.innerHTML = `${page}`
        pageContent.classList.add('loaded')
        loadAllAdverts(props)
        // Handle JotForm
        document.querySelectorAll('.module-jotform').forEach((element, index) => {
          const scriptTag = element.querySelector('script')
          const id = `jotform-${index}`
          element.setAttribute('id', id)
          postscribe(`#${id}`, `<script src="${scriptTag.getAttribute('src')}"></script>`);
        })

        document.querySelectorAll('.module-gallery [data-module="modules/slider"]').forEach((element) => {

          const slides = []

          element.querySelectorAll('.slide').forEach((slide) => {
            slides.push(<div dangerouslySetInnerHTML={{ __html: slide.innerHTML }}></div>)
          })

          if (slides) {

            const sliderID = `slider-ref-${new Date().getTime()}`
            const root = ReactDOM.createRoot(element.parentElement);
            const settings = {
              prevArrow: <button type="button" class="slick-prev"><i class="icon icon-i-pfeil-links"></i></button>,
              nextArrow: <button type="button" class="slick-next"><i class="icon icon-i-pfeil-rechts"></i></button>,
              dots: false,
              afterChange: function (i) {
                document.querySelector(`#${sliderID} .slider-current-slide`).innerHTML = (i + 1)
              },
              draggable: false,
              mobileFirst: true,
              slidesToScroll: 1,
              slidesToShow: 1.4,
              // slidesToShow: 1.4,
              infinite: false,
              arrows: true,
              responsive: [
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
            };

            root.render(<div id={sliderID}>
              <Slider className='slider' {...settings}>
                { slides }
              </Slider>
              <div class="slider-pagination"><span class="slider-current-slide">1</span><span class="slider-total-slides">{ slides.length }</span></div>
            </div>);
          }
        })

        // Is member?
        MODEL.checkIfMember().then((isMember) => {
          if (!isMember) {
            document.querySelectorAll('[data-firebase="non-member-overlay-warning"]').forEach((element) => {
              element.style.display = 'block'
            })
          }
        })

      }).catch((err) => {
        element.innerHTML = isPressPage ? `${presswallMarkup}` : `${memberwallMarkup}`
        pageContent.classList.add('loaded')

        // Is member?
        MODEL.checkIfMember().then((isMember) => {
          if (!isMember) {
            document.querySelectorAll('[data-firebase="non-member-overlay-warning"]').forEach((element) => {
              element.style.display = 'block'
            })
          }
        })
      })
    }).catch((err) => {
      // Before trying to obtain protected content, check to see if we have a customToken which should be exchanged for a Firebase session.
      const token = localStorage.getItem('token')

      MODEL.login(token).then((user) => {
        // Check if this user has access to the page (as it's a private page)
        MODEL.getPage(postID, isPressPage).then((page) => {
          pageContent.innerHTML = `${page}`
          pageContent.classList.add('loaded')
        }).catch((err) => {
          element.innerHTML = isPressPage ? `${presswallMarkup}` : `${memberwallMarkup}`
          pageContent.classList.add('loaded')

          // Is member?
          MODEL.checkIfMember().then((isMember) => {
            if (!isMember) {
              document.querySelectorAll('[data-firebase="non-member-overlay-warning"]').forEach((element) => {
                element.style.display = 'block'
              })
            }
          })
        })
      }).catch((err) => {
        element.innerHTML = isPressPage ? `${presswallMarkup}` : `${memberwallMarkup}`
        pageContent.classList.add('loaded')
      })

      element.innerHTML = isPressPage ? `${presswallMarkup}` : `${memberwallMarkup}`
      pageContent.classList.add('loaded')

      // Is member?
      MODEL.checkIfMember().then((isMember) => {
        if (!isMember) {
          document.querySelectorAll('[data-firebase="non-member-overlay-warning"]').forEach((element) => {
            element.style.display = 'block'
          })
        }
      })
    })
  }
})

function getCookie(name) {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function parseJwt(token) {
  if (token) {
    var base64Url = token.split('.')[1];
    if (base64Url) {

      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } else {
      return null
    }
  }
}

const Menu = observer((props) => {
  const element = document.querySelector('[data-firebase-module="menu"]')
  const language = element ? element.getAttribute('language') : null;

  let menu_source = language === 'en' ? props.data.menu_en : language === 'zh-hans' ? props.data.menu_zh_hans : props.data.menu_de

  // Subsites can have their menu. If they don't, fallback to one of the above.
  if (window.location.href.includes('schalke04.de/tv/')) {
    menu_source = props.data.menu_tv
  } else if (window.location.href.includes('schalke04.de/basketball/')) {
    menu_source = props.data.menu_basketball
  } else if (window.location.href.includes('schalke04.de/tischtennis/')) {
    menu_source = props.data.menu_tischtennis
  } else if (window.location.href.includes('schalke04.de/handball/')) {
    menu_source = props.data.menu_handball
  } else if (window.location.href.includes('schalke04.de/esports/')) {
    menu_source = props.data.menu_esports
  } else if (window.location.href.includes('schalke04.de/knappenschmiede/')) {
    menu_source = props.data.menu_knappenschmiede
  } else if (window.location.href.includes('schalke04.de/leichtathletik/')) {
    menu_source = props.data.menu_leichtathletik
  } else if (window.location.href.includes('schalke04.de/knappenkids/')) {
    menu_source = props.data.menu_knappenkids
  } else if (window.location.href.includes('schalke04.de/bolzplaetze/')) {
    menu_source = props.data.menu_bolzplaetze
  } else if (window.location.href.includes('schalke04.de/frauenfussball/')) {
    menu_source = props.data.menu_frauenfussball
  } else if (window.location.href.includes('schalke04.de/sportabteilungen/')) {
    menu_source = props.data.menu_sportabteilungen
  }

  let menu = ''

  // Build the menu
  menu_source.forEach((item) => {

    let children = ``

    if (item.children) {

      item.children.forEach((child) => {

        let grandchildren = ``

        if (child.children) {

          child.children.forEach((grandchild) => {

            grandchildren += `
              <li data-members-only="${grandchild.is_members_only && grandchild.is_members_only === 'true' ? 'true' : 'false'}" class="header-nav-item nav-item-newscenter menu-item menu-item-type-post_type menu-item-object-page menu-item-22136 item-first leaf ${grandchild.classes ? `${grandchild.classes}` : ''}">
                <a class="header-nav-link" data-members-only="${grandchild.is_members_only && grandchild.is_members_only === 'true' ? 'true' : 'false'}" href="${grandchild.link}">${grandchild.label}</a>
              </li>
            `
          })
        }

        children += `
          <li data-members-only="${child.is_members_only && child.is_members_only === 'true' ? 'true' : 'false'}" class="header-nav-item nav-item-newscenter menu-item menu-item-type-post_type menu-item-object-page menu-item-151 menu-item-has-children item-first has-submenu ${child.classes ? `${child.classes}` : ''}">
            <a href="${child.link}" data-members-only="${child.is_members_only && child.is_members_only === 'true' ? 'true' : 'false'}" class="${grandchildren ? 'header-nav-trigger' : ''}" role="button" aria-haspopup="true" aria-expanded="false">${child.label}</a>
            <div class="header-nav-menu-container level-3">
              <ul class="header-nav-submenu level-3" data-back="Zurück" data-root="${child.label}">
                ${ grandchildren }
              </ul>
            </div>
          </li>
        `
      })
    }

    menu += `
      <li data-members-only="${item.is_members_only && item.is_members_only === 'true' ? 'true' : 'false'}" class="header-nav-item nav-item-aktuelles menu-item menu-item-type-post_type menu-item-object-page menu-item-144 menu-item-has-children item-first has-submenu ${item.classes ? `${item.classes}` : ''}">
        <a href="${item.link}" data-members-only="${item.is_members_only && item.is_members_only === 'true' ? 'true' : 'false'}" class="${children ? 'header-nav-trigger' : ''}" role="button" aria-haspopup="true" aria-expanded="false">${item.label}</a>
        <div class="header-nav-menu-container level-2">
          <ul class="header-nav-submenu level-2" data-back="Zurück" data-root="${item.label}">
            ${ children }
          </ul>
        </div>
      </li>`
  })

  element.innerHTML = `
    <nav class="nav-main" data-module='[ [ "modules/nav-main--mobile", "media:{(max-width:1199px)}" ], [ "modules/nav-main--desktop", "media:{(min-width:1200px)}" ] ]'>
      <div class="header-nav-menu-container level-1">
        <ul class="header-nav-menu level-1">
          ${ menu }
        </ul>
      </div>
    </nav>
  `

  // BOF: legacy JS
  if (typeof $ !== 'undefined') {
    // eslint-disable-next-line
    $('.header-nav-submenu', '.nav-main').each($.proxy(function(index, submenu) {
      // eslint-disable-next-line
      $(submenu).prepend('<li class="back"><span>' + $(submenu).data('back') + '</span></li>');
      // eslint-disable-next-line
      if ($(submenu).data('root')) {
        // eslint-disable-next-line
        $(submenu).find('[data-submenu-title]').remove();
        // eslint-disable-next-line
      	$(submenu).find('.back').after('<li data-submenu-title class="root"><span>' + $(submenu).data('root') + '</span></li>');
      }
    }, this));

    // eslint-disable-next-line
    $('.nav-main').on('click', '.header-nav-trigger', function(event) {
      if (window.innerWidth <= 1199) {
        event.preventDefault();

        // eslint-disable-next-line
        var trigger = $(event.currentTarget);

        // trigger.find('.header-nav-submenu');
        trigger.closest('li').addClass('open');
        trigger.closest('.header-nav-menu-container').addClass('submenu-activated');
      }
    });

    // eslint-disable-next-line
    $('.nav-main').on('click', '.back', function(event) {
      event.preventDefault();

      // eslint-disable-next-line
      var back = $(event.currentTarget);

      back.closest('.header-nav-menu-container').parent().removeClass('open').closest('.header-nav-menu-container').removeClass('submenu-activated');
    });
  }
  // EOF: legacy JS

  if (typeof window.parseToken !== 'undefined') {
    window.parseToken()
  }
})

const Table = observer((props) => {
  const element = document.querySelectorAll('[data-firebase-module="table"]')

  const tableHeader = document.querySelector(".module.module-league-table .headline.headline-with-link h2 a[data-firebase-reference='table-button']");
  const tableButton = document.querySelector(".module.module-league-table .headline.headline-with-link .headline-with-link--link a[data-firebase-reference='table-button']");

  let contentLang = document.documentElement.lang ==="en-US"?"en":"de"

  if(tableHeader && contentLang === "en"){
    tableHeader.innerHTML = "Table"
  }

  const tableLabels = {
    en: {
      "club":"Club",
      "position":"Position",
      "played":"Matches",
      "points":"Points"
    },
    de : {
      "club":"Verein",
      "position":"Platz",
      "played":"Spiele",
      "points":"Punkte"
    }
  }

  element.forEach((element) => {
    const isPreview = element.getAttribute('preview') === 'true'
    const links = document.querySelectorAll('[data-firebase-reference="table-button"]')

    links.forEach((element) => {
      element.setAttribute('href', props.data.table_link)
    })

    let ourPosition = 0
    let entries = ''

    props.data.table.map((team, index) => {
      if (team.name === 'FC Schalke 04') {
        ourPosition = index
      }
    })

    var positionsToShow = []

    if (isPreview) {
      if (ourPosition == 0) { // Top of the league (Glück auf!)
        positionsToShow = [0, 1, 2, 3, 4]
      } else if (ourPosition <= 4) {
        positionsToShow = [0, 1, 2, 3, 4]
      } else if (ourPosition >= 15) {
        positionsToShow = [13, 14, 15, 16, 17]
      } else {
        for (var i = 2; i > 0; i--) {
          positionsToShow.push(ourPosition - i)
        }
        for (var i = 0; i < 3; i++) {
          positionsToShow.push(ourPosition + i)
        }
      }
    } else {
      positionsToShow = props.data.table.map((team, index) => { return index })
    }

    positionsToShow.forEach((index) => {
      var team            = props.data.table[index]
      var slug            = team.name.toLowerCase().replace(/ /g, '-').replace('ü', 'ue').replace('ö', 'oe').replace('ß','ss').replace(' ','-').replace('-zh-hans-1','').replace('-zh-hans-2','').replace('-zh-hans-3','').replace('-u12-ii','').replace('-u12','').replace('-u13','').replace('-u14','')
      .replace('-u15','').replace('-u16','').replace('-u17','').replace('-u18u19','').replace('-u18','').replace('-u19','').replace('-u20','').replace('-u21','').replace('-u22','').replace('-u23','').replace('-i-b-i','').replace('-en','')
      var highlightClass  = '';
      var specialStyle    = '';

      if (team.name == 'FC Schalke 04') { highlightClass = 'highlight';  }

      // highlights 3rd position ( Bundesliga 1 promotion playoff ) and 16th ( Bundesliga 3. relegation playoff ) optimal for Bundesliga 2 )
      // if (index == 1 || index == 2 || index == 14 || index == 15) { specialStyle = 'border-bottom: 1px solid #ff7c28;' }

      //Highlights the 5th position (UEFA Europa Lague), 6th ( UEFA conference league ) and 16th ( Bundesliga 2 relegation playoff ) optimal for Bundesliga 1
      if (index === 3 || index === 4 || index === 5 || index === 14 || index === 15) { specialStyle = 'border-bottom: 1px solid #ff7c28;' }

      entries += '<tr class="' + highlightClass + '" style="' + specialStyle + '"><td class="position">' + (index + 1) + '</td><th class="logo" scope="row"><img loading="lazy" src="/content/global/teams/' + slug + '.png" width="18" height="18" alt=""></th><td class="club">' + team.name + '</td><td class="played">' + team.played + '</td><td class="points">' + team.points + '</td></tr>'
    })

    element.innerHTML = `
      <div class="league-table-body">
        <div class="widget widget_matchcentercurrentstandings_widget">
          <table data-feeds="table-short" class="table table-striped table-sm">
            <thead>
              <tr>
              <th class="position">${tableLabels[contentLang].position}</th>
              <th class="club" colspan="2">${tableLabels[contentLang].club}</th>
              <th class="played">${tableLabels[contentLang].played}</th>
              <th class="points">${tableLabels[contentLang].points}</th>
              </tr>
            </thead>
            <tbody>
              ${ entries }
            </tbody>
          </table>
        </div>
      </div>
    `
  })
})

const LivetickerHome = observer((props) => {
  const element = document.querySelectorAll('[data-firebase-module="liveticker-home"]')

  element.forEach((element) => {

    if (props.data.liveticker.entries_de && props.data.liveticker.entries_de.length > 0) {

      element.innerHTML = `
        <div id="live-ticker" class="new-live-ticker" data-module="modules/liveticker" style="margin-top:32px!important;" data-processed="true" data-loading="modules/liveticker">
          <div class="container-fluid">
            <a href="${props.data.latest_match.url}" class="live-ticker" data-module="modules/ticker" data-options="{ &quot;mode&quot;: &quot;bar&quot;, &quot;match&quot;: 6 }" data-id="" data-hash="da39a3ee5e6b4b0d3255bfef95601890afd80709" data-subscription="psl.fcs04.matchcenter.tickerbarupd.6" data-processed="true" data-loading="modules/ticker">
              <div class="sticky-ticker-comment hidden-lg-down">
                <p class="ticker-comment-header">
                  <span class="live-ticker-header red">LIVE</span>
                  <span class="live-ticker-header white">TICKER</span> | <strong></strong>
                </p>
              </div>
              <div class="ticker-standing" data-module="https://schalke04.de/content/plugins/fcs04-matchcenter/resources/public/assets/js/modules/event.js" data-options="{ &quot;match&quot;: 6 }" data-subscription="psl.fcs04.matchcenter.match.event.6" data-processed="true" data-initialized="https://schalke04.de/content/plugins/fcs04-matchcenter/resources/public/assets/js/modules/event.js">
                <div class="standing-team standing-team--home">
                  <div class="team-logo objectfit">
                    <img src="https://schalke04.de/content/global/teams/${props.data.latest_match.home_team.toLowerCase().replace(/ /g, '-').replace(/ /g, '-').replace('ü', 'ue').replace('ö', 'oe')}.png" alt="" class="initial lazyloading" data-was-processed="true">
                  </div>
                </div>
                <div class="standing-standing">
                  <span class="" data-update="postmeta.score_home">${props.data.latest_match.home_score ? props.data.latest_match.home_score : '-'}</span><span class="standing-space">:</span><span class="" data-update="postmeta.score_away">${props.data.latest_match.away_score ? props.data.latest_match.away_score : '-'}</span>
                </div>
                <div class="standing-team standing-team--away">
                  <div class="team-logo objectfit">
                    <img src="https://schalke04.de/content/global/teams/${props.data.latest_match.away_team.toLowerCase().replace(/ /g, '-').replace(/ /g, '-').replace('ü', 'ue').replace('ö', 'oe')}.png" alt="" class="initial lazyloading" data-was-processed="true">
                  </div>
                </div>
              </div>
              <div class="ticker-comment">
                <p class="ticker-comment-header">
                  <span class="live-ticker-header red">LIVE</span>
                  <span class="live-ticker-header white">TICKER</span> | <strong> ${props.data.liveticker.meta.match_label ? `${props.data.liveticker.meta.match_label}` : ``}</strong>
                </p>
                <p class="ticker-comment-content">${props.data?.liveticker?.entries_de ? props.data?.liveticker.entries_de[0]?.comment : ''}</p>
              </div>
            </a>
          </div>
        </div>
      `

      // Hide the teaser module
      if (document.querySelector('.firebase-latest-match')) {
        document.querySelector('.firebase-latest-match').style.display = 'none'
      }

    } else {

      element.innerHTML = ``

      // Show the teaser module
      if (document.querySelector('.firebase-latest-match')) {
        document.querySelector('.firebase-latest-match').style.display = 'block'
      }
    }
  })
})

const LatestMatch = observer((props) => {
  const element = document.querySelectorAll('[data-firebase-module="latest-match"]')
  let contentLang = document.documentElement.lang ==="en-US"?"en":"de"

  const config = {
    en: {
      "countdownLabel": 'Next game',
      "days": "Days",
      "hours": "Hours",
      "minutes": "Min.",
      "seconds": "Sec.",
      "friendlies": "Friendlies",
      "cup": "DFB Cup",
      "tableUrl": window.fcs04_data ? window.fcs04_data.table_page_url : "https://schalke04.de/en/aktuelles/matchcenter/",
      "matchcenterUrl": "https://schalke04.de/en/aktuelles/matchcenter/",
      "matchUrl": props.data.latest_match.url.replace("aktuelles","en/aktuelles"),
      "club":"Club",
      "position":"Position",
      "matches":"Matches",
      "points":"Points",
      "table": "Table"
    },
    de : {
      "countdownLabel": 'Nächstes Spiel',
      "days":"Tage",
      "hours":"Std.",
      "minutes":"Min.",
      "seconds":"Sek.",
      "friendlies":"Testspiel",
      "cup": "DFB Pokal",
      "tableUrl": window.fcs04_data ? window.fcs04_data.table_page_url : "https://schalke04.de/aktuelles/matchcenter/",
      "matchcenterUrl": "https://schalke04.de/aktuelles/matchcenter/",
      "matchUrl":props.data.latest_match.url,
      "club":"Verein",
      "position":"Platz",
      "matches":"Spiele",
      "points":"Points",
      "table": "Tabelle"
    }
  }

  element.forEach((element) => {

    let competition = ''

    if (props.data.latest_match.competition === 'league') {
      competition = JSON.parse(MODEL.global).league == '2' ? '2. Bundesliga' : 'Bundesliga'
    } else if (props.data.latest_match.competition === 'cup') {
      competition = config[contentLang].cup
    } else if (props.data.latest_match.competition === 'friendlies') {
      competition = config[contentLang].friendlies
    }

    const t = props.data.latest_match.timestamp.replace('T', ' ').split(/[- :]/);
    const kickoff = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
    const date = contentLang == "de"?`${getGermanDay(kickoff)} ${t[2]}.${Number(t[1])}.${t[0].slice(-2)} | ${t[3]}.${t[4]} Uhr` // 'Sa. 09.4.22 | 13:30 Uhr'
    :`${getEnglishDay(kickoff)} ${t[2]}.${Number(t[1])}.${t[0]} | ${t[3]}.${t[4]}` // 'Sa. 09.4.22 | 13:30
    const hasGameBegan = getDurToDist(kickoff) <= 0;

    const standingHTML = hasGameBegan ?
      `<span class="" data-update="postmeta.score_home">${props.data.latest_match.home_score ? props.data.latest_match.home_score : '-'}</span>
      <span class="standing-space">:</span>
      <span class="" data-update="postmeta.score_away">${props.data.latest_match.away_score ? props.data.latest_match.away_score : '-'}</span>`
      :
      `<a href="${config[contentLang].matchUrl}" target="" class="btn btn-module-games gameplan">Matchcenter</a>`;

    const rightCTA = hasGameBegan ?
      `<a href="${config[contentLang].matchcenterUrl}" target="" class="btn btn-module-games gameplan">Matchcenter</a>`
      :
      `<a href="https://tickets.schalke04.de/" target=" _blank" class="btn btn-module-games btn-save-tickets">Tickets</a>`;

    element.innerHTML = `
      <div class="module-games-container" data-module="modules/schichtplan" data-liveticker="0">
        <div class="module module-games mb-4">
          <div class="row">
            <div class="col-xl-6 col-md-12  order-md-2 order-xl-1 countdown hidden visible-md" data-module="modules/countdown" data-date="" data-matchid="">
              <div class="row countdown-label-wrapper"><div class="col-24 mb-2 text-center countdown-label">${config[contentLang].countdownLabel}</div></div>
              <div class="row countbox-row">
                <div class="col-6 col-md-6 countbox text-center">
                  <div class="countbox-number" data-latest-match-days></div>
                  <div class="countbox-value">${config[contentLang].days}</div>
                </div>
                <div class="col-6 col-md-6 countbox text-center">
                  <div class="countbox-number" data-latest-match-hours></div>
                  <div class="countbox-value">${config[contentLang].hours}</div>
                </div>
                <div class="col-6 col-md-6 countbox text-center">
                  <div class="countbox-number" data-latest-match-minutes></div>
                  <div class="countbox-value">${config[contentLang].minutes}</div>
                </div>
                <div class="col-6 col-md-6 countbox text-center">
                  <div class="countbox-number" data-latest-match-seconds></div>
                  <div class="countbox-value">${config[contentLang].seconds}</div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-md-24 col-24 order-md-1 order-xl-2 game-slider">
              <div class="row games-date-label">
                <strong class="league-name">${competition} |</strong>
                <span class="league-date" data-type="1"> ${date} </span>
              </div>
              <div class="schichtplan-slider">
                <div class="stage-slide">
                  <div class="games-body has-score">
                    <div class="games-date">
                      <div class="slide-el-standing-standing">${standingHTML}</div>
                    </div>
                    <div class="games-team games-team--home">
                      <div class="team-logo objectfit">
                        <img src="https://schalke04.de/content/global/teams/${props.data.latest_match.home_team.toLowerCase().replace(/ /g, '-').replace('ü', 'ue').replace('ö', 'oe')}.png" alt="${props.data.latest_match.home_team}">
                      </div>
                    </div>
                    <div class="games-team games-team--away">
                      <div class="team-logo objectfit">
                        <img src="https://schalke04.de/content/global/teams/${props.data.latest_match.away_team.toLowerCase().replace(/ /g, '-').replace('ü', 'ue').replace('ö', 'oe')}.png" alt="${props.data.latest_match.away_team}">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-md-12 col-24 order-md-3 order-xl-3 partner-section">
              <a href="${props.data.epg.url}" target="_blank" class="logo-partner ${props.data.epg.enabled ? `` : `hidden`}" aria-label="Sky">
                <img src="${props.data.epg.image}" class="img-fluid" alt="">
              </a>
              <div class="button-group match-settings-next-next">
                <a href="${config[contentLang].tableUrl}" target=" _blank" class="btn btn-module-games mb-2">${config[contentLang].table}</a>
                ${rightCTA}
              </div>
            </div>
          </div>
        </div>
      </div>
    `

    const daysElement = element.querySelector('[data-latest-match-days]')
    const hoursElement = element.querySelector('[data-latest-match-hours]')
    const minutesElement = element.querySelector('[data-latest-match-minutes]')
    const secondsElement = element.querySelector('[data-latest-match-seconds]')

    setDate(daysElement, hoursElement, minutesElement, secondsElement, kickoff)

    // Clear any previous intervals
    if (latestMatchInterval) { clearInterval(latestMatchInterval) }

    latestMatchInterval = setInterval(() => {
      setDate(daysElement, hoursElement, minutesElement, secondsElement, kickoff)
    }, 1000)
  })
})

function getDurToDist(distTime) {
  let germanTime = new Date(new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" }));
  let now = new Date(Date.UTC(germanTime.getFullYear(), germanTime.getMonth(), germanTime.getDate(), germanTime.getHours(), germanTime.getMinutes(), germanTime.getSeconds())).getTime() / 1000;

  return (distTime.getTime() / 1000) - now;
}

function setDate(daysElement, hoursElement, minutesElement, secondsElement, kickoff) {
  const distanceVenue = getDurToDist(kickoff)

  let days = '00'
  let hours = '00'
  let minutes = '00'
  let seconds = '00'

  if (distanceVenue > 0){
    days = Math.floor(distanceVenue / (60 * 60 * 24))
    hours = Math.floor((distanceVenue % (60 * 60 * 24)) / (60 * 60))
    minutes = Math.floor((distanceVenue % (60 * 60)) / (60))
    seconds = Math.floor((distanceVenue % (60)))

    days = days < 10 ? `0${days}` : days
    hours = hours < 10 ? `0${hours}` : hours
    minutes = minutes < 10 ? `0${minutes}` : minutes
    seconds = seconds < 10 ? `0${seconds}` : seconds
  }

  daysElement.innerHTML = days
  hoursElement.innerHTML = hours
  minutesElement.innerHTML = minutes
  secondsElement.innerHTML = seconds
}

let newsletterProfleIsReady = false

const NewsletterProfile = observer((props) => {

  if (!newsletterProfleIsReady) {

    newsletterProfleIsReady = true

    const elements = document.querySelectorAll('[data-firebase-module="newsletter-profile"]')

    elements.forEach((element) => {

      if (window.location.search && decodeURI(window.location.search.replace('?', ''))) {
        let paramSets = decodeURI(window.location.search.replace('?', '')).split('&')

        let a = ''
        let b = ''
        let email = ''
        let source = ''

        paramSets.forEach((params) => {
          params = params.split('=')

          if (params[0] === 'a') {
            a = params[1]
          } else if (params[0] === 'b') {
            b = params[1]
          } else if (params[0] === 'c') {
            email = params[1]
          } else if (params[0] === 'd') {
            source = params[1];
          }
        })

        // No optional information for the geno-newsletter
        if (source === 'Geno-Landingpage') {
          element.style.display = 'none';
          return;
        }

        // Show the user their own email address (only for presentation)
        document.querySelector('#field--account--email').value = email

        element.classList.remove('loading')

        // Listen for form submissions.
        document.querySelector('[name="form-register"]').addEventListener('submit', (ev) => {
          ev.preventDefault()

          element.classList.add('loading')

          const profile = {
            'salutation': ev.target.querySelector('[name="salutation"]').value,
            'firstname': ev.target.querySelector('[name="firstname"]').value,
            'lastname': ev.target.querySelector('[name="lastname"]').value,
            'dob-day': ev.target.querySelector('[name="dob-day"]').value,
            'dob-month': ev.target.querySelector('[name="dob-month"]').value,
            'dob-year': ev.target.querySelector('[name="dob-year"]').value,
            'street': ev.target.querySelector('[name="street"]').value,
            'zipcode': ev.target.querySelector('[name="zipcode"]').value,
            'city': ev.target.querySelector('[name="city"]').value,
            'country': ev.target.querySelector('[name="country"]').value
          }

          axios
          // .post(`http://localhost:5001/schalke-04-app/europe-central2/updateNewsletterProfile`,
          .post(`https://europe-central2-schalke-04-app.cloudfunctions.net/updateNewsletterProfile`,
            {
              a,
              b,
              profile
            }
          )
          .then((response) => {
            window.location = response.data.redirect
          })
        })
      }
    })
  }
})

let newsletterFormIsReady = false

const NewsletterForm = observer((props) => {

  if (!newsletterFormIsReady) {

    newsletterFormIsReady = true

    const elements = document.querySelectorAll('[data-firebase-module="newsletter-form"]')

    elements.forEach((element) => {

      document.querySelector('.module-newsletter').classList.remove('loading')

      // Listen for form submissions.
      element.addEventListener('submit', (ev) => {
        ev.preventDefault()

        document.querySelector('.module-newsletter').classList.add('loading')

        // Ensure the user has agreed to terms and age restriction.
        if (!element.querySelector('#field-newsletter-age').checked) {
          return
        }
        if (!element.querySelector('#field-newsletter-privacy').checked) {
          return
        }

        axios
        // .post(`http://localhost:5001/schalke-04-app/europe-central2/newsletterSignup`,
        .post(`https://europe-central2-schalke-04-app.cloudfunctions.net/newsletterSignup`,
          {
            email: element.querySelector('#field-newsletter-email').value,
            source: element.querySelector('[name="source"]').value,
          }
        )
        .then((response) => {
          window.location = response.data.redirect
        })
      })
    })
  }
})

const KnappenComment = observer((props) => {
  //https://sdn-global-live-streaming-packager-cache.3qsdn.com/14935/14935_264_live.m3u8
  // Erste stream "https://mcdn.daserste.de/daserste/de/master.m3u8"
  let streamUrl = props.data.audio.media_url
  if(!props.data.audio.enabled || props.data.audio.media_url === "") {
      return;
  }

  const element = document.querySelector('[data-firebase-module="knappen-comment"]')

  if (!element) return;
  const isHomepage = document.body.classList.contains('page-template-homepage');

  let isPlaying     = sessionStorage.getItem("knappen-kommentar-playing") === "true"?true:false;
  const streamSource  = sessionStorage.getItem("knappen-kommentar-src")

  if(streamSource !== streamUrl){
    sessionStorage.setItem("knappen-kommentar-src", streamUrl);
  }

  if(!isHomepage) return; //Prevents running in other pages than the homepage

  const playerElement = document.getElementById('knappen-kommentar-player');
  if(!playerElement){
    console.log("Player not found");
    return;
  }

  const playerSource = document.querySelector("audio#knappen-kommentar-player source");

  if(playerElement && playerSource.src !== streamUrl){
    playerSource.src = streamUrl;

    setTimeout(function() {
      playerElement.load();
    },1000);

  }else{
    playerElement.load();
  }

  const playButton   = document.getElementById('knappen-comment-play');

  element.addEventListener('click', (e) => {
    e.preventDefault();

    if(isPlaying){
      sessionStorage.setItem("knappen-kommentar-playing",false)
      playerElement.pause()
      playButton.style.backgroundImage = 'url("https://schalke04.de/content/themes/fcschalke04/assets/images/knappen-audio-play-off.svg")';


    }else{
      sessionStorage.setItem("knappen-kommentar-playing",true)
      playerElement.play();
      playButton.style.backgroundImage = 'url("https://schalke04.de/content/themes/fcschalke04/assets/images/knappen-audio-play-on.svg")';
    }

    isPlaying = !isPlaying;
  });

  element.style.display = "block";

})

MODEL.loadData()

export default observer(
  class App extends React.Component {
    constructor(props) {
      super(props)
    }
    render() {
      return JSON.parse(MODEL.data) ?
      <>
        <Menu data={ JSON.parse(MODEL.data) } />
        <LatestMatch data={ JSON.parse(MODEL.data) } />
        <LivetickerHome data={ JSON.parse(MODEL.data) } />
        <LivetickerMatch />
        <Table data={ JSON.parse(MODEL.data) } />
        <MemberWall data={ JSON.parse(MODEL.data) } />
        <Account data={ JSON.parse(MODEL.data) } />
        <Video />
        <Matchcenter />
        <Advert data={ JSON.parse(MODEL.data) } />
        <Search />
        <SearchShortcuts />
        <NewsletterProfile />
        <NewsletterForm />
        <KnappenComment data={ JSON.parse(MODEL.data) }/>
        <GlobalHospitality />
      </>
      : null
    }
  }
)
