import { types, flow, applySnapshot } from 'mobx-state-tree'
import { initializeApp } from "firebase/app"
import { getFirestore, collection, doc, setDoc, getDoc, onSnapshot } from "firebase/firestore"
import { getAuth, signInWithCustomToken, signOut, onAuthStateChanged } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyCkfSkh80tXHOGuRj_zmPeP8PyN1MEUgyA",
    authDomain: "schalke-04-app.firebaseapp.com",
    databaseURL: "https://schalke-04-app.firebaseio.com",
    projectId: "schalke-04-app",
    storageBucket: "schalke-04-app.appspot.com",
    messagingSenderId: "231417877135",
    appId: "1:231417877135:web:e07c73c5c647eef1ce16fa",
    measurementId: "G-TR3PJQXNRZ"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (user) {
    MODEL.setUser(user)
  }
});

const MODEL = types
    .model('data', {
        data: types.optional(types.string, JSON.stringify('')),
        global: types.optional(types.string, JSON.stringify('')),
        latest_match: types.optional(types.string, JSON.stringify('')),
        uid: types.optional(types.string, ''),
        search_shortcuts: types.optional(types.string, JSON.stringify(''))
    })
    .actions((self) => {
        return {
            getToken: () => {
                return new Promise((resolve, reject) => {
                    auth.onAuthStateChanged(function(user) {
                        if (user) {
                            user.getIdToken().then(function(idToken) {
                                resolve(idToken)
                            })
                        } else {
                            reject()
                        }
                    });
                })
            },
            setUser: (user) => {
                self.uid = user.uid
            },
            checkIfMember: () => {
                return new Promise((resolve, reject) => {
                    const auth = getAuth();

                    if (auth.currentUser) {
                        auth.currentUser.getIdToken().then((token) => {
                            let decoded = parseJwt(token);
                            resolve(decoded.member)
                        }).catch((err) => {
                            resolve(false)
                        })
                    }
                })
            },
            getUser: () => {
                return new Promise((resolve, reject) => {
                    const auth = getAuth();
                    const user = auth.currentUser;

                    if (user) {
                        resolve(user)
                    } else {
                        reject()
                    }
                })
            },
            logout: () => {
                const auth = getAuth();

                // Unset token cookie
                localStorage.removeItem('token');

                signOut(auth).then(() => {
                  window.location = 'https://account.schalke04.de/services/auth/idp/oidc/logout'
                }).catch((error) => {
                  console.log('FIREBASE LOGOUT ERROR', error)
                });
            },
            login: (customToken) => {
                return new Promise((resolve, reject) => {
                    const auth = getAuth()

                    signInWithCustomToken(auth, customToken)
                    .then((userCredential) => {
                        localStorage.setItem('token', customToken);

                        // Convert login button to a profile button
                        const loginButtons = document.querySelectorAll('[data-firebase-reference="account-login-link"]')
                        loginButtons.forEach((button) => {
                            button.innerHTML = 'Profil'
                        })
                        
                        resolve(userCredential.user)
                    })
                    .catch((error) => {
                        console.log('FIREBASE LOGIN ERROR', error.code, error.message)
                        reject()
                    });
                })
            },
            loadData: () => {
                onSnapshot(doc(db, "pages", "home"), (doc) => {
                    self.setData(doc.data())
                });
                self.getGlobalSettings()
            },
            getPage: (pageID, isPressPage = false) => {
                return new Promise((resolve, reject) => {

                    if (isPressPage) {

                        const docRef = doc(db, "press", `${pageID}`);

                        getDoc(docRef).then((docSnap) => {
                            let data = docSnap.data()
                            resolve(data.page)
                        }).catch((err) => {
                            console.log('getPage (press) catch', err)
                            reject()
                        })

                    } else {

                        const docRef = doc(db, "pages", `${pageID}`);

                        getDoc(docRef).then((docSnap) => {
                            let data = docSnap.data()
                            resolve(data.page)
                        }).catch((err) => {
                            console.log('getPage catch', err)
                            reject()
                        })
                    }
                })
            },
            setData: (data) => {
                self.data = JSON.stringify(data)
            },
            // setScreens: (data) => {
            //     self.screens = JSON.stringify(data)
            // },
            setGlobalSettings: (data) => {
                self.global = JSON.stringify(data)
            },
            setLatestMatch: (data) => {
                self.latest_match = JSON.stringify(data)
            },
            setSpecificMatch: (data) => {
                self.specific_match = JSON.stringify(data)
            },
            setSearchShortcuts: (data) => {
                self.search_shortcuts = JSON.stringify(data)
            },
            getVideo: (videoID) => {
                return new Promise((resolve, reject) => {
                    
                    const docRef = doc(db, "videos", `${videoID}`);

                    getDoc(docRef).then((docSnap) => {
                        let data = docSnap.data()
                        resolve(data.video)
                    }).catch((err) => {
                        console.log('getVideo catch', err)
                        reject()
                    })
                })
            },
            getGlobalSettings: () => {
                return new Promise((resolve, reject) => {
                    
                    const docRef = doc(db, "global", `settings`);

                    getDoc(docRef).then((docSnap) => {
                        let data = docSnap.data()
                        self.setGlobalSettings(data)
                        resolve(data)
                    }).catch((err) => {
                        console.log('getGlobalSettings catch', err)
                        reject()
                    })
                })
            },
            getSeason: (season) => {
                return new Promise((resolve, reject) => {
                    
                    const docRef = doc(db, "seasons", `${season}`);

                    getDoc(docRef).then((docSnap) => {
                        let data = docSnap.data()
                        resolve(data)
                    }).catch((err) => {
                        console.log('getSeason catch', err)
                        reject()
                    })
                })
            },
            getSpecificMatch: (matchID) => {
                return new Promise((resolve, reject) => {

                    onSnapshot(doc(db, "matches", `${matchID}`), (doc) => {
                        self.setSpecificMatch(doc.data())
                    });
                })
            },
            getSearchShortcuts: () => {
                return new Promise((resolve, reject) => {

                    onSnapshot(doc(db, "search", "shortcuts"), (doc) => {
                        self.setSearchShortcuts(doc.data())
                        resolve(doc.data())
                    });
                })
            },
            getMatch: (matchID) => {
                return new Promise((resolve, reject) => {

                    onSnapshot(doc(db, "matches", `${matchID}`), (doc) => {
                        self.setLatestMatch(doc.data())
                    });
                })
            },
        }
    })
    .create();

function parseJwt(token) {
    if (token) {
      var base64Url = token.split('.')[1];
      if (base64Url) {
        
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
      
        return JSON.parse(jsonPayload);
      } else {
        return null
      }
    }
  }

  export default MODEL;